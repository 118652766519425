import { CommonModule } from '@angular/common';
import { Component, effect, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { LanguageService } from '@mzic/mzic-services';
import { HeaderComponent } from '../../../../components/header/header.component';

@Component({
  selector: 'mzic-page-desktop',
  standalone: true,
  imports: [CommonModule, HeaderComponent],
  templateUrl: './desktop.component.html',
  styleUrl: './desktop.component.scss',
})
export class DesktopComponent implements OnInit {
  @Input() assetId?: string;
  @Input() videoUrl?: string;
  @Input() qrCodeUrl?: string;

  languageService = inject(LanguageService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  translocoService = inject(TranslocoService);

  constructor() {
    effect(() => {
      const lang = this.languageService.languageState();
      const current = this.router.url.split('/').pop();

      if (lang !== '') {
        this.router.navigate([`${lang}/assets/${current}`]);
      }
    });
  }

  ngOnInit() {
    this.setLanguageFromRoute();
  }

  setLanguageFromRoute() {
    this.activatedRoute.params.subscribe((params) => {
      const lang = params['lang'];
      if (lang) {
        this.translocoService.setActiveLang(lang); // Atualiza o Transloco
        this.languageService.languageState.set(lang); // Atualiza o estado do idioma
      }
    });
  }
}
