import { Composer } from './models';

export const composersMzic1Data: Composer[] = [
  {
    id: 'matheus-marcolino',
    name: 'Matheus Marcolino',
    bio: [
      'Cantor e compositor natural de Sarandi, Paraná, Matheus Marcolino iniciou sua carreira aos 18 anos e se destacou como um dos principais nomes da nova geração da música sertaneja.',
      'Conhecido por sua habilidade em transformar emoções profundas em letras marcantes, já compôs mais de 100 músicas e soma mais de 28 milhões de streams no Spotify. Entre suas músicas de destaque estão “DEJA VU” (Luan Santana, Ana Castela) e “ABALO EMOCIONAL” (Luan Santana) e “Nem Doeú” (Matheus & Kauan).',
      'Além de seu trabalho como compositor, Matheus também investe na carreira solo e busca transformar suas vivências pessoais em canções, refletindo sua influência no cenário musicl atual.',
    ],
    compositions: [
      {
        music: 'Deja Vu',
        artistsName: 'Luan Santana, Ana Castela',
        cover: 'dejavu.jpeg',
      },
      {
        music: 'Abalo Emocional – Ao Vivo',
        artistsName: 'Luan Santana',
        cover: 'abalo-emocional.jpeg',
      },
      {
        music: 'Nem Doeu – Ao Vivo',
        artistsName: 'Matheus & Kauan',
        cover: 'nem-doeu.jpeg',
      },
      {
        music: 'Sorria',
        artistsName: 'Luan Santana, Mc Don Juan',
        cover: 'sorria.jpeg',
      },
      {
        music: 'Bobinha – Ao Vivo',
        artistsName: 'Jorge & Mateus',
        cover: 'terra-sem-cep.jpeg',
      },
    ],
  },
  {
    id: 'cristhyan-ribeiro',
    name: 'Cristhyan Ribeiro',
    bio: [
      'Cristhyan Ribeiro é um compositor brasileiro que se consolidou como um dos maiores criadores de sucessos na cena sertaneja contemporânea.',
      'Reconhecido por sua habilidade em transformar sentimentos em letras marcantes, Cristhyan compôs hits que embalam milhões de fãs. Entre seus maiores sucessos estão “Infrato” (Diego & Victor Hugo), “Barzinho Aleatório” e “Textão” (Zé Neto & Cristiano), além de “Briga Feia” (Henrique & Juliano) e “Peguei Ranço” (Maiara & Maraisa).',
      'Suas composições se destacam por contar histórias envolventes e por conectar com o público de forma genuína, confirmando seu lugar como referência indispensável na música sertaneja contemporânea.',
    ],
    compositions: [
      {
        music: 'Briga Feia – Ao Vivo',
        artistsName: 'Henrique & Juliano',
        cover: 'briga-feia.jpeg',
      },
      {
        music: 'Estranho',
        artistsName: 'Marília Mendonça',
        cover: 'estranho.jpeg',
      },
      {
        music: 'Deu Moral',
        artistsName: 'Zé Neto & Cristiano',
        cover: 'deu-moral.jpeg',
      },
      {
        music: 'Cidade Vizinha – Ao Vivo',
        artistsName: 'Henrique & Juliano',
        cover: 'cidade-visinha.jpeg',
      },
      {
        music: 'Ela e Ela',
        artistsName: 'Zé Neto & Cristiano',
        cover: 'ela-e-ela.jpeg',
      },
    ],
  },
  {
    id: 'flavinho-tinto',
    name: 'Flavinho Tinto',
    bio: [
      'Cantor e compositor, Flavinho Tinto nasceu em Uberaba/MG. Superando uma infância difícil, começou a compor na adolescência e encontrou na música uma forma de expressar seus sentimentos e desventuras.',
      'Foi segunda voz uma dupla sertaneja mineira, e, em 2005, teve uma de suas composições gravada por um artista de renome nacional. Fundou com seus parceiros, Nando e Douglas Mello, a Fabrika Hitz, a primeira casa de composição do país.',
      'Flavinho assina mais de mil composições e soma mais de 3 bilhões de visualizações no Youtube. Entre seus sucessos estão “Facas” – Diego e Victor Hugo feat. Bruno e Marrone, “Mulher Maravilha” – Zé Neto e Cristiano e “Pulei na Piscina” – Guilherme e Benuto.',
    ],
    compositions: [
      {
        music: 'Facas – Ao Vivo',
        artistsName: 'Diego & Victor Hugo, Bruno & Marrone',
        cover: 'facas.jpeg',
      },
      {
        music: '3 Batidas (Ao Vivo)',
        artistsName: 'Guilherme & Benuto',
        cover: '3batidas.jpeg',
      },
      {
        music: 'Pulei na Piscina',
        artistsName: 'Guilherme & Benuto',
        cover: 'puleiNaPiscina.jpeg',
      },
      {
        music: 'Mulher Maravilha – Ao Vivo',
        artistsName: 'Zé Neto & Cristiano',
        cover: 'mulher-maravilha.jpeg',
      },
      {
        music: 'Textão – Ao Vivo',
        artistsName: 'Zé Neto & Cristiano',
        cover: 'textao.jpeg',
      },
    ],
  },
  {
    id: 'douglas-mello',
    name: 'Douglas Mello',
    bio: [
      'Douglas Mello é um cantor e compositor nascido em Sacramento, interior de Minas Gerais. Vindo de uma família musicl, ele aprendeu violão aos 9 anos e descobriu sua paixão pela música.',
      'Reconhecido pela facilidade em compor letras cativantes, suas canções já foram gravadas por diversos artistsName renomados. Entre seus maiores sucessos estão “Desbloqueado – Ao Vivo” (Diego & Victor Hugo), “Facilita” – Ao Vivo (Marília Mendonça & Maiara & Maraisa) e “Para de Me Chamar Pra Trair” (Matheus & Kauan).',
      'Junto com Flavinho Tinto e Nando Marx, Douglas é um dos sócios fundadores da Fabrika Hitz, que acumula mais de 4 bilhões de plays em suas composições.',
    ],
    compositions: [
      {
        music: 'Desbloqueado – Ao Vivo',
        artistsName: 'Diego & Victor Hugo',
        cover: 'desbloqueado.jpeg',
      },
      {
        music: 'Para de Me Chamar',
        artistsName: 'Marília Mendonça, Maiara & Maraisa',
        cover: 'para-de.jpeg',
      },
      {
        music: 'Facilita – Ao Vivo',
        artistsName: 'Luan Santana',
        cover: 'facilita.jpeg',
      },
      {
        music: 'Cidade Vizinha – Ao Vivo',
        artistsName: 'Henrique & Juliano',
        cover: 'cidade-visinha.jpeg',
      },
      {
        music: '3 Batidas (Ao Vivo)',
        artistsName: 'Guilherme & Benuto',
        cover: '3batidas.jpeg',
      },
    ],
  },
  {
    id: 'nando-marx',
    name: 'Nando Marx',
    bio: [
      'Nando Marx, cantor e compositor natural de Igarapava, São Paulo, iniciou sua carreira aos 18 anos, tocando em casas de shows, rádios, bandas regionais e abrindo shows de artistsName como Bruno & Marrone e Zezé Di Camargo & Luciano.',
      'Seu dom na composição foi logo reconhecido, e em pouco tempo de carreira assinou com a Som Livre, uma das maiores gravadoras do país. Entre seus sucessos estão “Trégua (Eu Quero Trégua)” (Bruno & Marrone), “Para de Me Chamar Pra Trair” (Marília Mendonça e Maiara & Maraisa) e “Raiva” (Israel & Rodolffo).',
      'É um dos sócios fundadores da Fabrika Hitz, a primeira casa de composições do Brasil que acumula mais de 4 bilhões de plays em suas composições.',
    ],
    compositions: [
      {
        music: 'Trégua (Eu Quero Trégua)...',
        artistsName: 'Bruno & Marrone',
        cover: 'tregua.jpeg',
      },
      {
        music: 'Para de Me Chamar',
        artistsName: 'Marília Mendonça, Maiara & Maraisa',
        cover: 'para-de.jpeg',
      },
      {
        music: 'Raiva – Ao Vivo',
        artistsName: 'Israel & Rodolffo',
        cover: 'raiva.jpeg',
      },
      {
        music: 'Facas – Ao Vivo',
        artistsName: 'Diego & Victor Hugo, Bruno & Marrone',
        cover: 'facas.jpeg',
      },
      {
        music: 'Ela e Ela',
        artistsName: 'Zé Neto & Cristiano',
        cover: 'ela-e-ela.jpeg',
      },
    ],
  },
];

export const composersMzic2Data: Composer[] = [
  {
    id: 'luan-santana',
    bio: [
      'Luan Santana é reconhecido como um dos maiores nomes da música sertaneja brasileira. Com uma carreira consolidada como cantor, ele também se destaca como um compositor versátil e de grande influência na cena sertaneja.',
      'Autor de sucessos como “quando a bad bater” (Luan Santana), “Cê Topa” (Luan Santana), “Anti-Amor” (Gustavo Mioto, Jorge & Matheus) e “Escreve Aí” (Luan Santana), Luan afirma que tem como objetivo ser um artista completo: capaz de cantar, tocar, compor e criar.',
      'Nos últimos 10 anos, tornou-se um dos compositores mais presentes do topo das rádios brasileiras, emplacando 6 hits de sua autoria ou coautoria entre as 10 músicas mais tocadas.',
    ],
    name: 'Luan Santana',
    compositions: [
      {
        music: 'MORENA',
        artistsName: 'Luan Santana',
        cover: 'morena.jpeg',
      },
      {
        music: 'ABALO EMOCIONAL - Ao Vivo',
        artistsName: 'Luan Santana',
        cover: 'abalo-emocional.jpeg',
      },
      {
        music: 'MEIO TERMO - Ao Vivo',
        artistsName: 'Luan Santana',
        cover: 'meio-termo.jpeg',
      },
      {
        music: 'quando a bad bater (ao vivo)',
        artistsName: 'Luan Santana',
        cover: 'quando-a-bad-bater.jpeg',
      },
      {
        music: 'CORAÇÃO CIGANO - Ao Vivo',
        artistsName: 'Luan Santana, Luísa Sonza',
        cover: 'coracao-cigano.jpeg',
      },
    ],
  },
  {
    id: 'matheus-marcolino',
    name: 'Matheus Marcolino',
    bio: [
      'Cantor e compositor natural de Sarandi, Paraná, Matheus Marcolino iniciou sua carreira aos 18 anos e se destacou como um dos principais nomes da nova geração da música sertaneja.',
      'Conhecido por sua habilidade em transformar emoções profundas em letras marcantes, já compôs mais de 100 músicas e soma mais de 28 milhões de streams no Spotify. Entre suas músicas de destaque estão “DEJA VU” (Luan Santana, Ana Castela) e “ABALO EMOCIONAL” (Luan Santana) e “Nem Doeú” (Matheus & Kauan).',
      'Além de seu trabalho como compositor, Matheus também investe na carreira solo e busca transformar suas vivências pessoais em canções, refletindo sua influência no cenário musicl atual.',
    ],
    compositions: [
      {
        music: 'Deja Vu',
        artistsName: 'Luan Santana, Ana Castela',
        cover: 'dejavu.jpeg',
      },
      {
        music: 'Abalo Emocional – Ao Vivo',
        artistsName: 'Luan Santana',
        cover: 'abalo-emocional.jpeg',
      },
      {
        music: 'Nem Doeu – Ao Vivo',
        artistsName: 'Matheus & Kauan',
        cover: 'nem-doeu.jpeg',
      },
      {
        music: 'Sorria',
        artistsName: 'Luan Santana, Mc Don Juan',
        cover: 'sorria.jpeg',
      },
      {
        music: 'Bobinha – Ao Vivo',
        artistsName: 'Jorge & Mateus',
        cover: 'terra-sem-cep.jpeg',
      },
    ],
  },
  {
    id: 'lucas-santos',
    bio: [
      'Lucas Santos é um compositor, produtor musical e multi-instrumentalista brasileiro que mora atualmente em Los Angeles, EUA.',
      'Ao longo de sua carreira, coescreveu e produziu mais de 200 músicas, incluindo 11 canções que atingiram o #1 das mais ouvidas no Brasil. Já emplacou mais de 20 músicas no Top 40 do Brasil, recebeu 2 indicações no Grammy Latino e acumula mais de 8 bilhões de streams nas plataformas digitais.',
      'Lucas já colaborou com artistas renomados como Anitta, Luan Santana, Alok, Matheus & Kauan, Jorge & Matheus e Henrique & Juliano. É representado pela Stargate (LAAMP Musica) em parceria com a Pulse Music Group e trabalha em seu próprio estúdio em Woodland Hills.',
    ],
    name: 'Lucas Santos',
    compositions: [
      {
        music: 'MORENA',
        artistsName: 'Luan Santana',
        cover: 'morena.jpeg',
      },
      {
        music: 'Ao Vivo E A Cores',
        artistsName: 'Matheus & Kauan, Anitta',
        cover: 'ao-vivo-e-a-cores.jpeg',
      },
      {
        music: 'quando a bad bater (ao vivo)',
        artistsName: 'Luan Santana',
        cover: 'quando-a-bad-bater.jpeg',
      },
      {
        music: 'Vingança - Ao Vivo',
        artistsName: 'Luan Santana, MC Kekel',
        cover: 'vinganca.jpeg',
      },
      {
        music: 'Nessas Horas - Ao Vivo',
        artistsName: 'Matheus & Kauan',
        cover: 'nessas-horas.jpeg',
      },
    ],
  },
  {
    id: 'marco-esteves',
    bio: [
      'Marco Esteves, carinhosamente conhecido como “Marquinho”, descobriu a paixão pelas artes na infância e, ao longo dos anos, transformou esse talento em uma das carreiras mais respeitadas na composição musical brasileira.',
      'O carioca já coescreveu mais de 250 canções, somando mais de 3B de streams no Spotify. Entre seus grandes hits estão “Não Vitalício (Nunca Mais)” (Matheus & Kauan, Mari Fernandez), “Fala Mal de Mim - Ao Vivo” (Gusttavo Lima), “Só Você” (DENNIS e MC G15) e “Só Tem Eu” (Zé Felipe).',
      'Sua habilidade em transitar por diferentes estilos musicais e colaborar com diversos artistas consolida Marquinho como uma referência de versatilidade e criatividade.',
    ],
    name: 'Marco Esteves',
    compositions: [
      {
        music: 'Não Vitalício (Nunca Mais) [Ao Vivo]',
        artistsName: 'Matheus & Kauan, Mari Fernandez',
        cover: 'nao-vitalicio.jpeg',
      },
      {
        music: 'Fala Mal de Mim - Ao Vivo',
        artistsName: 'Gusttavo Lima',
        cover: 'fala-mal-de-mim.jpeg',
      },
      {
        music: 'Só Você',
        artistsName: 'DENNIS, MC G15',
        cover: 'so-voce.jpeg',
      },
      {
        music: 'Desejo Imortal (It Must Have Been love) - Ao Vivo',
        artistsName: 'Gusttavo Lima',
        cover: 'desejo-imortal.jpeg',
      },
      {
        music: 'Isso Que é Vida',
        artistsName: 'DENNIS, Cantini',
        cover: 'isso-que-e-vida.jpeg',
      },
    ],
  },
];
