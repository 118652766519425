import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MzicTooltipDirective } from '@mzic/mzic-directives';

@Component({
  selector: 'mzic-tooltip',
  standalone: true,
  imports: [CommonModule, MzicTooltipDirective],
  providers: [],
  templateUrl: './mzic-tooltip.component.html',
  styleUrls: ['./mzic-tooltip.component.scss'],
})
export class MzicTooltipComponent {
  @Input() content = '';
}
