<ng-container *transloco="let t">
  <mzic-header />
  <div class="coming-soon-page-container">
    <div class="content">
      <h1>{{ t('lang.title') }}</h1>
      <h2>{{ t('lang.description') }}</h2>
    </div>
    <div>
      <div class="date-time">
        <div class="days">{{ days }} {{ t('lang.days') }}</div>
        <div class="time">{{ hours }}H:{{ minutes }}M:{{ seconds }}S</div>
      </div>
    </div>
  </div>
</ng-container>
