import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MzicSvgComponent } from '@mzic/mzic-components';
import { composersMzic2Data } from '../../../../../../data/composers';
import { interpretersMzic2Data } from '../../../../../../data/interpreters';
import { Composer } from '../../../../../../data/models';
import { LyricsMzic2Component } from '../../../../lyrics/mzic2/mzic2.component';
import { Mzic1ContentComponent } from '../mzic1/mzic1.component';

registerLocaleData(localePt, 'pt-BR');

@Component({
  selector: 'mzic-content-mzic2',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    FormsModule,
    OverlayModule,
    RouterModule,
  ],
  providers: [DecimalPipe],
  templateUrl: './mzic2.component.html',
  styleUrl: './mzic2.component.scss',
})
export class Mzic2ContentComponent
  extends Mzic1ContentComponent
  implements OnInit
{
  override openLyrics() {
    this.dialog.open(LyricsMzic2Component, {
      maxWidth: '342px',
      maxHeight: '605px',
      panelClass: 'lyrics-dialog',
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.tokensAvailable = 2000;
    this.multiplier = 182.95192;

    // Seta dados do Mzic2
    this.composers = composersMzic2Data;
    this.composersName = this.composers.map((item: Composer) => item.name);
    this.composer = this.composers[0];

    this.interpreters = interpretersMzic2Data;
    this.interpreter = this.interpreters[0];
  }
}
