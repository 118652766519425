export interface ParamsArtistAccount {
  email: string;
  name: string;
  lastName: string;
  phoneNumber: string;
  dateBirth: string;
  viberateUuid: string;
}

export interface ParamsMediaUploadImage {
  cover: boolean;
  hash?: string;
}

export interface BaseCreateUserDto {
  walletType: 'BACKOFFICE';
  user: CreateUserBackofficeDto;
}
export interface CreateUserBackofficeDto {
  uuid?: string | null;
  email: string;
  fullName: string;
  phoneNumber: string;
  birthDate: string;
  username: string;
  password: string;
  region?: 'BR' | 'US';
  registryType?: 'ARTIST' | 'LABEL';
  artistName?: string | null;
  labelName?: string;
  labelDescription?: string;
  imageId?: string;
  language: 'ENGLISH' | 'PORTUGUESE_BR';
}

export type LanguageType = 'ENGLISH' | 'PORTUGUESE_BR';

export interface ParamsArtistAgree {
  userId: number;
  termsOfUse: boolean;
  privacyPolicy: boolean;
}

export interface ParamsArtistInstagramVerify {
  authorizationCode: string;
  viberateUuid: string;
  instagramUsername: string;
}

export interface ResponseCreateAccount {
  userId: number;
  email: string;
  username: string;
}

export interface UserProfileDTO {
  username: string;
  email: string;
  profilePhoto: string;
  backgroundPhoto: string;
  bio: string;
  walletId: number;
  createdAt: string;
  fullName: string;
  phoneNumber: string;
  chainWallet: string;
  cognito: string;
  userId: number;
  walletType: WalletType;
  countDataProfile: CountDataProfileDTO;
  phoneNumberValidate: boolean;
  region: string;
  gatewayType: string;
  socialSecurityNumber: string;
  cbo: string;
  motherFullName: string;
  pep: boolean;
  revenue: number;
  verified: boolean;
}

export interface CountDataProfileDTO {
  offer: number;
  certificate: number;
  favorite: number;
  song: number;
}

export enum WalletType {
  BACKOFFICE = 'BACKOFFICE',
  APP = 'APP',
}

export interface ResponseUploadImage {
  timestamp: number;
  status: string;
  data: string;
}
export interface ResponseAcceptTermsAndPolicy {
  timestamp: string;
  status: string;
}

export interface ResponseCheckUsername {
  timestamp: string;
  status: string;
  data: {
    exists: boolean;
  };
}
