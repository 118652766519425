<ng-container *transloco="let t">
  <header class="mzic-header">
    <div class="mzic-header__wrapper">
      <div
        class="mzic-header__logo"
        routerLink="/pt-br/home"
        (click)="openMenu.set(false)">
        <img src="assets/icons/mzic-logo-name.svg" />
      </div>
      <div class="mzic-header__menu" [ngClass]="openMenu() ? 'open' : ''">
        <nav class="mzic-header__menu__navbar">
          <ul class="mzic-header__menu__navbar__list">
            <li
              [class.mzic-header__menu__navbar__item--focus]="homeFocused"
              (click)="goToHome()">
              {{ t('lang.menu.1') }}
            </li>
            <li (click)="goToArtists()">
              {{ t('lang.menu.2') }}
            </li>
          </ul>

          <mzic-switch-language (languageChange)="switchLanguage($event)" />

          <ul class="mzic-header__menu__navbar__socials">
            <li>
              <a
                href="https://discord.gg/jGfy2WQyPx"
                target="_blank"
                class="mzic-io-header-icon">
                <lib-icon-discord />
              </a>
            </li>
            <!-- <li>
              <a href="https://x.com/mzicone" class="mzic-io-header-icon">
                <lib-icon-x-twitter />
              </a>
            </li> -->
            <li>
              <a
                [href]="instagramUrl"
                target="_blank"
                class="mzic-io-header-icon">
                <lib-icon-instagram />
              </a>
            </li>
          </ul>
        </nav>
        <div class="mzic-header__menu__icon" (click)="handleMenuClick()">
          <span class="mzic-header__menu__icon__hamburger"></span>
        </div>
      </div>
    </div>
  </header>
</ng-container>

<!-- <header>
  <div>
    <img src="/assets/icons/mzic-logo-name.svg" class="logo" />
  </div>
  <div>
    <nav class="menu">
      <ul>
        <li>Início</li>
        <li>Para Artistas</li>
        <li class="low-margin">EN</li>
        <li class="low-margin no-hover arrow active">
          @if (true) {
            →
          } @else {
            ←
          }
        </li>
        <li class="low-margin active">PT</li>
      </ul>
    </nav>
    <div class="icons">
      <li>
        <a href="#" class="mzic-io-header-icon">
          <lib-icon-discord />
        </a>
      </li>
      <li>
        <a href="#" class="mzic-io-header-icon">
          <lib-icon-x-twitter />
        </a>
      </li>
      <li>
        <a href="#" class="mzic-io-header-icon">
          <lib-icon-instagram />
        </a>
      </li>
    </div>
  </div>
</header> -->
