import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'mzic-redirect-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './redirect-Page.component.html',
  styleUrl: './redirect-Page.component.scss',
})
export class RedirectPageComponent {}
