export const interpretersMzic1Data = [
  {
    name: 'Guilherme & Benuto',
    social: [
      {
        title: 'Spotify',
        accountLink:
          'https://open.spotify.com/intl-pt/artist/6m6e7D2TnV0aYMllFFwMxu?si=o9nZKzL6Q1-4ri9_lBFcow',
        accountName: 'Guilherme & Benuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1,3M',
          },
          {
            label: 'Ouvintes mensais',
            value: '9,2M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: 'https://www.youtube.com/@GuilhermeeBenuto',
        accountName: '@GuilhermeBenuto',
        blocks: [
          {
            label: 'Inscritos',
            value: '2,5M',
          },
          {
            label: 'Visualizações',
            value: '2,7B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: 'https://www.instagram.com/guilhermeebenuto/',
        accountName: '@guilhermeebenuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1,9M',
          },
          {
            label: 'Engajamento',
            value: '0.5%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: 'https://www.tiktok.com/@guilhermeebenuto',
        accountName: '@guilhermeebenuto',
        blocks: [
          {
            label: 'Seguidores',
            value: '1,1M',
          },
          {
            label: 'Curtidas',
            value: '8M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'Haja Colírio – Ao Vivo',
        artist: 'Guilherme & Benuto, Hugo & Guilherme',
        cover: 'haja-colirio.jpeg',
        social: [
          {
            value: '303,5M',
            label: 'Streams',
          },
          {
            value: '324M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Duas Três',
        artist: 'Guilherme & Benuto, Hugo & Guilherme',
        cover: 'DuasTres.jpeg',
        social: [
          {
            value: '301,7M',
            label: 'Streams',
          },
          {
            value: '72,7M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Assunto Delicado',
        artist: 'Guilherme & Benuto',
        cover: 'ASsuntoDelicado.jpeg',
        social: [
          {
            value: '214,5M',
            label: 'Streams',
          },
          {
            value: '140,3M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: '3 Batidas – Ao Vivo',
        artist: 'Guilherme & Benuto',
        cover: '3batidas.jpeg',
        social: [
          {
            value: '188M',
            label: 'Streams',
          },
          {
            value: '178M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Pulei na Piscina – Ao Vivo',
        artist: 'Guilherme & Benuto, DJ Lucas Beat',
        cover: 'puleiNaPiscina.jpeg',
        social: [
          {
            value: '170M',
            label: 'Streams',
          },
          {
            value: '248M',
            label: 'Visualizações',
          },
        ],
      },
    ],
  },
  {
    name: 'Simone Mendes',
    social: [
      {
        title: 'Spotify',
        accountLink:
          'https://open.spotify.com/intl-pt/artist/2eK9gcJQ6uqVvJL63dnOM3?si=1VOBfn-FQfKNXwKI3zj7mQ',
        accountName: 'Simone Mendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '2,3M',
          },
          {
            label: 'Ouvintes mensais',
            value: '10,4M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: 'https://www.youtube.com/channel/UCH7vP1JyEwTy-D-ikZUoCww',
        accountName: '@simonemendesoficial',
        blocks: [
          {
            label: 'Inscritos',
            value: '4,8M',
          },
          {
            label: 'Visualizações',
            value: '1,66B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: 'https://www.instagram.com/simonemendes',
        accountName: '@simonemendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '39,6M',
          },
          {
            label: 'Engajamento',
            value: '0.3%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: 'https://www.tiktok.com/@simoneses',
        accountName: '@simonemendes',
        blocks: [
          {
            label: 'Seguidores',
            value: '16,6M',
          },
          {
            label: 'Curtidas',
            value: '94M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'Erro Gostoso – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'ErroGostoso.jpeg',
        social: [
          {
            value: '368,4M',
            label: 'Streams',
          },
          {
            value: '385M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Daqui pra Sempre',
        artist: 'MANU, Simone Mendes',
        cover: 'DaquiPraSempre.jpeg',
        social: [
          {
            value: '214,9M',
            label: 'Streams',
          },
          {
            value: '412M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Dois Tristes – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'DoisTristes.jpeg',
        social: [
          {
            value: '138,8M',
            label: 'Streams',
          },
          {
            value: '174M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Oceano – Ao Vivo',
        artist: 'Felipe & Rodrigo, Simone Mendes',
        cover: 'Oceano.jpeg',
        social: [
          {
            value: '64,4M',
            label: 'Streams',
          },
          {
            value: '25M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Mulher Foda – Ao Vivo',
        artist: 'Simone Mendes',
        cover: 'MulherFoda.jpeg',
        social: [
          {
            value: '61,3M',
            label: 'Streams',
          },
          {
            value: '6M',
            label: 'Visualizações',
          },
        ],
      },
    ],
  },
];

export const interpretersMzic2Data = [
  {
    name: 'Luan Santana',
    social: [
      {
        title: 'Spotify',
        accountLink:
          'https://open.spotify.com/intl-pt/artist/3qvcCP2J0fWi0m0uQDUf6r?si=iGunNjoxT1az4_qc3NdDIw',
        accountName: 'Luan Santana',
        blocks: [
          {
            label: 'Seguidores',
            value: '8,2M',
          },
          {
            label: 'Ouvintes mensais',
            value: '11,8M',
          },
        ],
      },
      {
        title: 'Youtube',
        accountLink: 'https://www.youtube.com/channel/UC6rwiIxv0w2fbmmr66wl1rA',
        accountName: '@LuanSantana',
        blocks: [
          {
            label: 'Inscritos',
            value: '12,8M',
          },
          {
            label: 'Visualizações',
            value: '7,1B',
          },
        ],
      },
      {
        title: 'Instagram',
        accountLink: 'https://www.instagram.com/luansantana/',
        accountName: '@luansantana',
        blocks: [
          {
            label: 'Seguidores',
            value: '36,1M',
          },
          {
            label: 'Engajamento',
            value: '0.5%',
          },
        ],
      },
      {
        title: 'TikTok',
        accountLink: 'https://www.tiktok.com/@luansantana',
        accountName: '@luansantana',
        blocks: [
          {
            label: 'Seguidores',
            value: '5,2M',
          },
          {
            label: 'Curtidas',
            value: '24,2M',
          },
        ],
      },
    ],
    musics: [
      {
        name: 'MORENA',
        artist: 'Luan Santana',
        cover: 'morena.jpeg',
        social: [
          {
            value: '223,4M',
            label: 'Streams',
          },
          {
            value: '213,2M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'ABALO EMOCIONAL - Ao Vivo',
        artist: 'Luan Santana',
        cover: 'abalo-emocional.jpeg',
        social: [
          {
            value: '202,8M',
            label: 'Streams',
          },
          {
            value: '150,1M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'MEIO TERMO - Ao Vivo',
        artist: 'Luan Santana',
        cover: 'meio-termo.jpeg',
        social: [
          {
            value: '200,2M',
            label: 'Streams',
          },
          {
            value: '70,4M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'ERRO PLANEJADO - Ao Vivo',
        artist: 'Luan Santana, Henrique & Juliano',
        cover: 'erro-planejado.jpeg',
        social: [
          {
            value: '177,5M',
            label: 'Streams',
          },
          {
            value: '105,2M',
            label: 'Visualizações',
          },
        ],
      },
      {
        name: 'Namorando Ou Não - Ao Vivo',
        artist: 'Luan Santana, Clayton & Romário',
        cover: 'namorando-ou-nao.jpeg',
        social: [
          {
            value: '166,5M',
            label: 'Streams',
          },
          {
            value: '159,1M',
            label: 'Visualizações',
          },
        ],
      },
    ],
  },
];
