<div class="lyrics">
  <header class="lyrics__header">
    <button (click)="closeLyrics()" class="mz-button mini only-icon link">
      <mzic-svg class="w24" src="assets/icons/mzic-close.svg"></mzic-svg>
    </button>
  </header>
  <h1 class="lyrics__title">Manda um Oi</h1>
  <p class="lyrics__text">
    Você já foi de A a Z
    <br />
    No meus contatos
    <br />
    Já revirou todas conversas de cima pra baixo
  </p>
  <p class="lyrics__text">
    Não viu foto, não viu vídeo, não viu nada suspeito
    <br />
    Mas o erro do errado é achar que faz tudo direito
  </p>
  <p class="lyrics__text">
    Mas um detalhe não escapou do seu olho
    <br />
    Ela cismou com um contato diferente e disse
    <br />
    Fala com ela agora na minha frente
    <br />
    Manda um “oi” aí pra eu ver
    <br />
    Ela falando com você
    <br />
    Pra eu ver o grau de intimidade Se o “tem nada a ver” é mesmo nada a ver
  </p>

  <p class="lyrics__text">
    Manda um “oi” aí pra eu ver
    <br />
    O que ela vai responder
    <br />
    Você tá a uma mensagem de provar o que você vale Ou de me perder
  </p>

  <p class="lyrics__text">
    Aí não teve o que fazer
    <br />
    Aí eu vou falar o que?
  </p>
</div>
