import { CommonModule } from '@angular/common';
import { Component, inject, Input, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  provideTranslocoScope,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { SwitchLanguageComponent } from '@mzic/mzic-components';
import { environment } from '@mzic/mzic-environments';
import { LanguageService } from '@mzic/mzic-services';
import { IconDiscordComponent } from '../../icons/icon-discord.component';
import { IconXTwitterComponent } from '../../icons/icon-x-twitter.component';
import { IconInstagramComponent } from './../../icons/icon-instagram.component';

@Component({
  selector: 'mzic-header',
  standalone: true,
  imports: [
    CommonModule,
    IconDiscordComponent,
    IconXTwitterComponent,
    IconInstagramComponent,
    SwitchLanguageComponent,
    TranslocoModule,
    RouterModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [
    provideTranslocoScope({
      scope: 'home',
      alias: 'lang',
    }),
  ],
})
export class HeaderComponent {
  @Input() homeFocused = true;

  protected openMenu = signal(false);
  protected activeLanguage!: string;
  protected instagramUrl = environment.instagramUS;
  protected artistsUrl = environment.artistsUrl;

  languageService = inject(LanguageService);
  translocoService = inject(TranslocoService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  protected handleMenuClick() {
    this.openMenu.update((value) => !value);
  }

  constructor() {
    // check the current language using transloco
    this.translocoService.langChanges$.subscribe((lang) => {
      if (lang === 'pt-br') {
        this.instagramUrl = environment.instagramBR;
      } else {
        this.instagramUrl = environment.instagramUS;
      }
    });
  }

  switchLanguage(language: string) {
    this.languageService.languageState.set(language);
    this.translocoService.setActiveLang(language);
  }

  goToHome() {
    const lang = this.languageService.languageState();
    if (lang !== '') {
      this.router.navigate([`${lang}/home`]);
    }
  }

  goToArtists() {
    this.openMenu.set(false);
    window.open(this.artistsUrl, '_blank');
  }
}
