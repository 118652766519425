<mzic-header [homeFocused]="false" />

<div class="landing-page">
  <!-- Video de fundo -->
  <video
    class="landing-page__background-video"
    [autoplay]="true"
    [muted]="true"
    [loop]="true"
    [playsInline]="true">
    <source [src]="videoUrl" type="video/mp4" />
    Seu navegador não suporta vídeos HTML5.
  </video>

  <!-- Conteúdo principal -->
  <div class="landing-page__content">
    <main class="landing-page__main">
      <h1 class="landing-page__title">
        Direitos de royalties musicais
        <span class="landing-page__highlight">{{ assetId }}</span>
      </h1>
      <div class="landing-page__buttons">
        <div class="landing-page__button">tokens de música</div>
        <div class="landing-page__button">royalties digitais</div>
      </div>
      <p class="landing-page__description">
        Escaneie o QR Code ao lado para acessar a lâmina completa dos direitos
        em oferta e explore detalhes do artista, análise das obras e dados
        financeiros.
      </p>
    </main>

    <!-- QR Code -->
    <div class="landing-page__qr-code">
      <img [src]="qrCodeUrl" alt="QR Code" />
    </div>
  </div>
</div>
