import { Pipe, PipeTransform } from '@angular/core';
import {
  addMonths,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from 'date-fns';

@Pipe({
  name: 'nextPayment',
  standalone: true,
})
export class NextPaymentPipe implements PipeTransform {
  transform(
    firstPaymentDate: string,
    totalYears = 10,
    cycle: 'monthly' | 'quarterly' | 'semiannual' | 'annual' = 'semiannual',
  ): string {
    // Encontra o número de meses do ciclo
    const cycleMonths =
      cycle === 'monthly'
        ? 1
        : cycle === 'quarterly'
          ? 3
          : cycle === 'semiannual'
            ? 6
            : 12;

    const today = new Date(Date.now());
    const parsedFirstPaymentDate = parseISO(firstPaymentDate);

    // Calcula a data final do ciclo
    const endDate = addMonths(parsedFirstPaymentDate, totalYears * 12);

    // Se já passou da data final, retorna ciclo encerrado
    if (today > endDate) {
      return 'Ativo expirado';
    }

    // Encontra o próximo pagamento
    let nextPaymentDate = parsedFirstPaymentDate;
    while (nextPaymentDate < today) {
      nextPaymentDate = addMonths(nextPaymentDate, cycleMonths);
    }

    // Se for hoje, retorna 'hoje'
    if (
      nextPaymentDate.getDate() === today.getDate() &&
      nextPaymentDate.getMonth() === today.getMonth() &&
      nextPaymentDate.getFullYear() === today.getFullYear()
    ) {
      return '~Hoje';
    }

    // Calcula a diferença em dias
    const diffDays = differenceInDays(nextPaymentDate, today);

    // Se for 0 dias, retorna 'hoje'
    if (diffDays === 0) {
      return '~Hoje';
    }

    // Se for menos de um mês, retorna apenas os dias
    if (diffDays < 31) {
      return `~${diffDays} dia${diffDays > 1 ? 's' : ''}`;
    }

    // Calcula meses completos e dias restantes usando date-fns
    const months = differenceInMonths(nextPaymentDate, today);
    const remainingDays = differenceInDays(
      nextPaymentDate,
      addMonths(today, months),
    );

    // Retorna mensagem formatada com meses e dias
    return `~${months} ${months === 1 ? 'mês' : 'meses'}${
      remainingDays > 0
        ? ` e ${remainingDays} dia${remainingDays > 1 ? 's' : ''}`
        : ''
    }`;
  }
}
