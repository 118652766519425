import { Route } from '@angular/router';
import { redirectAssetGuard } from '@mzic/mzic-guard';
import { AssetComponent } from './pages/asset/asset.component';
import { ComingSoonWithPreviewComponent } from './pages/coming-soon-with-preview/coming-soon-with-preview.component';
import { RedirectPageComponent } from './pages/redirect-page/redirect-Page.component';

export const mzicIoRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: ':lang/home',
    component: ComingSoonWithPreviewComponent,
    title: 'MZIC',
  },
  {
    path: ':lang/assets/:id',
    component: AssetComponent,
    title: 'MZIC',
  },
  {
    path: '**',
    canActivate: [redirectAssetGuard],
    component: RedirectPageComponent,
  },
];
