<ng-container *transloco="let t">
  @if (isMobile) {
    @if (data.assetId === 'MZIC1') {
      <mzic-content-mzic1 />
    } @else {
      <mzic-content-mzic2 />
    }
  } @else {
    <mzic-page-desktop
      [qrCodeUrl]="data.qrCodeUrl"
      [assetId]="data.assetId"
      [videoUrl]="data.videoUrl" />
  }
</ng-container>
