import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MzicSvgComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-lyrics-mzic2',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './mzic2.component.html',
  styleUrl: './mzic2.component.scss',
})
export class LyricsMzic2Component {
  dialog = inject(MatDialog);

  closeLyrics() {
    this.dialog.closeAll();
  }
}
