import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MzicSvgComponent } from '@mzic/mzic-components';

@Component({
  selector: 'mzic-lyrics-mzic1',
  standalone: true,
  imports: [CommonModule, MzicSvgComponent],
  templateUrl: './mzic1.component.html',
  styleUrl: './mzic1.component.scss',
})
export class LyricsMzic1Component {
  dialog = inject(MatDialog);

  closeLyrics() {
    this.dialog.closeAll();
  }
}
