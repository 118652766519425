import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

export const redirectAssetGuard: CanActivateFn = (route, state) => {
  const language = route.params['lang'];
  const translocoService = inject(TranslocoService);
  const router = inject(Router);
  const browserLang = translocoService.getActiveLang() || 'en';

  if (!language && state.url.includes('MZIC1')) {
    router.navigate([`/${browserLang}${state.url}`]);
    return false;
  }

  if (!language && state.url.includes('MZIC2')) {
    router.navigate([`/${browserLang}${state.url}`]);
    return false;
  }

  router.navigate([`/${browserLang}/home`]);
  return false;
};
