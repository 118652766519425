import { OverlayModule } from '@angular/cdk/overlay';
import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  registerLocaleData,
} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  MzicSvgComponent,
  SelectSimpleComponent,
  SelectSimpleOptionComponent,
} from '@mzic/mzic-components';
import { SelectOption } from '@mzic/mzic-interfaces';
import {
  AccumulationChartModule,
  AccumulationDataLabelService,
  AreaSeriesService,
  AxisModel,
  CategoryService,
  ChartAllModule,
  ChartAreaModel,
  ChartModule,
  DateTimeService,
  ILoadedEventArgs,
  ITooltipRenderEventArgs,
  MultiColoredAreaSeriesService,
  PieSeriesService,
  RangeAreaSeriesService,
  SplineRangeAreaSeriesService,
  StackingAreaSeriesService,
  StackingStepAreaSeriesService,
  StepAreaSeriesService,
  TooltipService,
  ZoomService,
} from '@syncfusion/ej2-angular-charts';
import { calendarMzic2 } from '../../../../../../data/calendar';
import { composersMzic2Data } from '../../../../../../data/composers';
import { interpretersMzic2Data } from '../../../../../../data/interpreters';
import { Composer, RevenueDataUI } from '../../../../../../data/models';
import { RevenueSourceMzicComponent } from '../../../../components/revenue-source/revenue-source.component';
import { MzicInvestmentSimulationSectionComponent } from '../../../../components/sections/investment-simulation-section/investment-simulation-section.component';
import { LyricsMzic2Component } from '../../../../lyrics/mzic2/mzic2.component';
import { NextPaymentPipe } from '../../../../pipes/next-payment.pipe';
import { Mzic1ContentComponent } from '../mzic1/mzic1.component';
import { QuarterData } from './../../../../../../data/models';

registerLocaleData(localePt, 'pt-BR');

@Component({
  selector: 'mzic-content-mzic2',
  standalone: true,
  imports: [
    CommonModule,
    MzicSvgComponent,
    FormsModule,
    OverlayModule,
    RouterModule,
    SelectSimpleComponent,
    SelectSimpleOptionComponent,
    ChartModule,
    ChartAllModule,
    AccumulationChartModule,
    MzicInvestmentSimulationSectionComponent,
    NextPaymentPipe,
  ],
  providers: [
    DecimalPipe,
    CurrencyPipe,
    AreaSeriesService,
    RangeAreaSeriesService,
    StepAreaSeriesService,
    StackingAreaSeriesService,
    DateTimeService,
    CategoryService,
    MultiColoredAreaSeriesService,
    StackingStepAreaSeriesService,
    SplineRangeAreaSeriesService,
    PieSeriesService,
    AccumulationDataLabelService,
    TooltipService,
    ZoomService,
  ],
  templateUrl: './mzic2.component.html',
  styleUrl: './mzic2.component.scss',
})
export class Mzic2ContentComponent
  extends Mzic1ContentComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('streamingAnalysis') streamingAnalysisButton!: ElementRef;
  @ViewChild('ecadAnalysis') ecadAnalysis!: ElementRef;

  currencyPipe = inject(CurrencyPipe);

  UIFilteredData!: RevenueDataUI;
  totalRevenue = 0;
  totalEcadRevenue = 0;
  totalStreamingRevenue = 0;
  yearsToFilter: SelectOption[] = [];
  yearSelected!: string;
  firstYearChange = true;
  calendar = calendarMzic2;

  detailedAnalysisView: 'ecad' | 'streaming' = 'streaming';

  doughnotData = [
    { x: 'Streaming', y: 0 },
    { x: 'ECAD', y: 0 },
  ];
  doughnotDatalabel = {
    visible: true,
    font: { size: 10, fontFamily: 'PP Mori', fontWeight: 600 },
    template: '<div>${point.y}%</div>',
  };

  // areachart configs
  areaChartData: { x: string; y: number }[] = [];
  areaChartConfig: ChartAreaModel = {
    border: {
      width: 0,
    },
  };
  areaChartZoomSettings = {
    enableSelectionZooming: true,
    enableAnimation: true,
    enablePan: true,
    enablePinchZooming: true,
    toolbarItems: [],
  };
  areaChartPrimaryXAxis: AxisModel = {
    zoomFactor: 0.79,
    valueType: 'Category',
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    minorTickLines: { width: 0 },
    majorTickLines: { width: 0 },
    lineStyle: {
      width: 0,
    },
    border: {
      width: 0,
    },
    labelStyle: {
      color: '#C5C5C5',
      fontFamily: 'PP Mori',
      fontWeight: '600',
    },
    scrollbarSettings: {
      enableZoom: false,
      trackRadius: 8,
      scrollbarRadius: 8,
      gripColor: 'transparent',
      trackColor: 'transparent',
      scrollbarColor: 'var(--purple-500)',
    },
  };
  areaChartPrimaryYAxis: AxisModel = {
    majorGridLines: {
      color: '#999ABA14',
      dashArray: '10',
    },
    minorGridLines: { width: 0 },
    minorTickLines: { width: 0 },
    majorTickLines: { width: 0 },
    labelStyle: {
      color: '#C5C5C5',
      fontFamily: 'PP Mori',
      fontWeight: '600',
    },
    labelFormat: '',
    lineStyle: {
      width: 0,
    },
    valueType: 'Double',
    minimum: 0,
    scrollbarSettings: {
      enableZoom: false,
    },
  };
  areaChartMarker = { visible: true, width: 10, height: 10, shape: 'Circle' };
  areaChartXBorder = {
    color: '#9655FF',
    width: 1.5,
  };
  areaChartTooltip = {
    enable: true,
    header: '${point.x}',
    fill: '#FFFFFF',
    enableMarker: false,
    opacity: 1,
    fadeOutDuration: 3000,
    textStyle: {
      fontFamily: 'PP Mori',
      color: '#111010',
      size: '14px',
    },
  };

  get frequency() {
    switch (this.calendar.cycleFrequency) {
      case 'monthly':
        return 'mensal';
      case 'quarterly':
        return 'trimestral';
      case 'semiannual':
        return 'semestral';
      case 'annual':
        return 'anual';
    }
  }

  get frequencyPlural() {
    switch (this.calendar.cycleFrequency) {
      case 'monthly':
        return 'mensais';
      case 'quarterly':
        return 'trimestrais';
      case 'semiannual':
        return 'semestrais';
      case 'annual':
        return 'anuais';
    }
  }

  areaChartTooltipRender(args: ITooltipRenderEventArgs | any): void {
    const formatedValue = this.formatToBrazilianCurrency(args.point.y);
    args.text = formatedValue;
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(args.point.y);
    args.text = formattedValue;
  }

  load(args: ILoadedEventArgs): void {
    if (args && args.chart && args.chart.zoomModule) {
      args.chart.zoomModule.isZoomed = true;
    }
  }

  areaChartHandleAxisValue(args: any) {
    if (args.axis.name === 'primaryYAxis') {
      const value = Number(args.text);
      if (value >= 1000000) {
        args.text = `R$${(value / 1000000).toLocaleString('pt-BR', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}M`;
      } else if (value >= 1000) {
        args.text = `R$${(value / 1000).toLocaleString('pt-BR', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}k`;
      } else {
        args.text = `R$${value.toLocaleString('pt-BR')}`;
      }
    }
  }
  // END areachart configs

  override openLyrics() {
    this.dialog.open(LyricsMzic2Component, {
      maxWidth: '342px',
      maxHeight: '605px',
      panelClass: 'lyrics-dialog',
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Seta dados do Mzic2
    this.composers = composersMzic2Data;
    this.composersName = this.composers.map((item: Composer) => item.name);
    this.composer = this.composers[0];

    this.interpreters = interpretersMzic2Data;
    this.interpreter = this.interpreters[0];

    if (this.interpreter.evolucao_receita) {
      this.populateYearsToFilter();
      this.populateUIFilteredData(
        this.interpreter.evolucao_receita.historico_trimestral,
      );
      this.yearSelected = '0';

      const {
        accumulated: { total, ecad, streaming },
      } = this.UIFilteredData;

      this.totalRevenue = total;
      this.totalEcadRevenue = ecad.total;
      this.totalStreamingRevenue = streaming.total;
    }
  }

  populateYearsToFilter() {
    const years = [
      ...new Set(
        this.interpreter.evolucao_receita?.historico_trimestral.map((triData) =>
          triData.trimestre?.substring(0, 4),
        ),
      ),
    ];
    years.forEach((year) => {
      this.yearsToFilter.push({ id: year, info: { title: year } });
    });
  }

  populateUIFilteredData(data: QuarterData[]) {
    const handledData = new RevenueDataUI();

    data.forEach((quarterData) => {
      handledData.accumulated.total += quarterData.receita_total;

      handledData.accumulated.ecad.total +=
        quarterData.receita_ecad.valor_total;
      quarterData.receita_ecad.breakdown_source.forEach((ecadSource) => {
        const source = handledData.accumulated.ecad.sources.find(
          (source) => source.name === ecadSource.source,
        );
        if (!source) {
          handledData.accumulated.ecad.sources.push({
            name: ecadSource.source,
            value: ecadSource.valor,
            percentage: 0,
          });
        } else {
          source.value += ecadSource.valor;
        }
      });

      handledData.accumulated.streaming.total +=
        quarterData.receita_streaming.valor_total;
      quarterData.receita_streaming.breakdown_plataforma.forEach(
        (plataform) => {
          const plataformFind =
            handledData.accumulated.streaming.plataforms.find(
              (source) => source.name === plataform.plataforma,
            );
          if (!plataformFind) {
            handledData.accumulated.streaming.plataforms.push({
              name: plataform.plataforma,
              value: plataform.valor,
              percentage: 0,
            });
          } else {
            plataformFind.value += plataform.valor;
          }
        },
      );

      // populate quarter data

      const findQuarter = handledData.quarterly.find(
        (quarter) => quarter.quarter === quarterData.trimestre,
      );

      if (!findQuarter) {
        handledData.quarterly.push({
          quarter: quarterData.trimestre,
          total: handledData.accumulated.total,
        });
      } else {
        findQuarter.total += handledData.accumulated.total;
      }
    });

    handledData.accumulated.ecad.percentage = this.formatAccumulatedPercentage(
      handledData.accumulated.ecad.total / handledData.accumulated.total,
    );
    handledData.accumulated.ecad.sources.sort(
      (sourceA, sourceB) => sourceB.value - sourceA.value,
    );
    handledData.accumulated.ecad.sources.forEach(
      (source) =>
        (source.percentage = this.formatAccumulatedPercentage(
          source.value / handledData.accumulated.ecad.total,
        )),
    );

    handledData.accumulated.streaming.plataforms.sort(
      (sourceA, sourceB) => sourceB.value - sourceA.value,
    );
    handledData.accumulated.streaming.percentage =
      this.formatAccumulatedPercentage(
        handledData.accumulated.streaming.total / handledData.accumulated.total,
      );
    handledData.accumulated.streaming.plataforms.forEach(
      (plataform) =>
        (plataform.percentage = this.formatAccumulatedPercentage(
          plataform.value / handledData.accumulated.streaming.total,
        )),
    );

    this.UIFilteredData = handledData;
    this.populateChartData();
  }

  formatAccumulatedPercentage(value: number): number {
    const formated = (value * 100).toFixed(2);
    const withoutDecimal = parseFloat(formated);
    return withoutDecimal === parseInt(withoutDecimal.toString())
      ? withoutDecimal
      : parseFloat(formated);
  }

  populateChartData() {
    this.doughnotData = [];
    if (this.UIFilteredData.accumulated.streaming.percentage) {
      this.doughnotData.push({
        x: 'Streaming',
        y: this.UIFilteredData.accumulated.streaming.percentage,
      });
    }
    if (this.UIFilteredData.accumulated.ecad.percentage) {
      this.doughnotData.push({
        x: 'ECAD',
        y: this.UIFilteredData.accumulated.ecad.percentage,
      });
    }

    const data: { x: string; y: number }[] = [];
    this.UIFilteredData.quarterly.forEach((quarterData) => {
      const findQuarter = data.find((chart) => chart.x === quarterData.quarter);

      if (!findQuarter) {
        data.push({
          x: quarterData.quarter,
          y: quarterData.total,
        });
      } else {
        findQuarter.y += quarterData.total;
      }
    });

    this.areaChartData = data;
  }

  changeRevenueYear(event: any) {
    if (this.firstYearChange) {
      this.firstYearChange = false;
      return;
    }

    if (event !== '0') {
      const year = event;
      const filteredData =
        this.interpreter.evolucao_receita?.historico_trimestral.filter(
          (triData) => {
            const anoDoTrimestre = triData.trimestre?.substring(0, 4);
            return anoDoTrimestre === year;
          },
        );

      this.populateUIFilteredData(filteredData || []);
      return;
    }

    this.populateUIFilteredData(
      this.interpreter.evolucao_receita?.historico_trimestral || [],
    );
  }

  changeDetailedAnalysis(viewSelected: 'ecad' | 'streaming') {
    this.detailedAnalysisView = viewSelected;
  }

  seeDetailedModal() {
    const data: { source: string; value: number; percentage: number }[] = [];

    if (this.detailedAnalysisView === 'ecad') {
      this.UIFilteredData.accumulated.ecad.sources.forEach((source) => {
        data.push({
          source: source.name,
          value: source.value,
          percentage: source.percentage,
        });
      });
    } else {
      this.UIFilteredData.accumulated.streaming.plataforms.forEach(
        (plataform) => {
          data.push({
            source: plataform.name,
            value: plataform.value,
            percentage: plataform.percentage,
          });
        },
      );
    }

    this.dialog.open(RevenueSourceMzicComponent, {
      width: '342px',
      maxHeight: '605px',
      panelClass: 'lyrics-dialog',
      data,
    });
  }

  formatToBrazilianCurrency(value: number): string {
    const formatter = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  }
}
