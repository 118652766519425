<div class="lyrics">
  <header class="lyrics__header">
    <button (click)="closeLyrics()" class="mz-button mini only-icon link">
      <mzic-svg class="w24" src="assets/icons/mzic-close.svg"></mzic-svg>
    </button>
  </header>
  <h1 class="lyrics__title">DEUS É MUITO BOM</h1>
  <p class="lyrics__text">
    Pro escuro da minha vida
    <br />
    Você foi um sol
    <br />
    Nesse mar tão turbulento
    <br />
    'Cê foi meu farol
  </p>

  <p class="lyrics__text">
    Se eu sou quem eu sou
    <br />
    É porque você chegou e arrumou
    <br />
    A bagunça, o caos
    <br />
    Meu milagre mais real
  </p>

  <p class="lyrics__text">
    Você me trouxe um caminhão de coisas boas
    <br />
    Foi minha sorte disfarçada de pessoa
    <br />
    Por tantas noites que eu passei juntando as mãos
    <br />
    Pedindo ao céu pra me mandar uma direção
  </p>

  <p class="lyrics__text">
    Deus é muito bom (oh oh oh)
    <br />
    Ouviu minha oração (oh oh oh oh)
    <br />
    Deus é muito bom (oh oh oh)
    <br />
    Mandou seu coração (oh oh oh oh)
  </p>

  <p class="lyrics__text">
    Pro escuro da minha vida
    <br />
    Você foi um sol
    <br />
    Nesse mar tão turbulento
    <br />
    'Cê foi meu farol
  </p>

  <p class="lyrics__text">
    Se eu sou quem eu sou
    <br />
    É porque você chegou e arrumou
    <br />
    A bagunça, o caos, mm
    <br />
    Meu milagre mais real
  </p>

  <p class="lyrics__text">
    Você me trouxe um caminhão de coisas boas
    <br />
    Foi minha sorte disfarçada de pessoa
    <br />
    Por tantas noites que eu passei juntando as mãos
    <br />
    Pedindo ao céu pra me mandar uma direção
  </p>

  <p class="lyrics__text">
    Você me trouxe um caminhão de coisas boas
    <br />
    Foi minha sorte disfarçada de pessoa
    <br />
    Por tantas noites que eu passei juntando as mãos
    <br />
    Pedindo ao céu pra me mandar uma direção
  </p>

  <p class="lyrics__text">
    Deus é muito bom (oh oh oh)
    <br />
    Ouviu minha oração (oh oh oh oh)
    <br />
    Deus é muito bom (oh oh oh)
    <br />
    Mandou seu coração (oh oh oh oh)
  </p>
</div>
